@import './variables';

@mixin placeholder() {
  input::-webkit-input-placeholder {
    color: $blue-grey;
    font-size: 15px;
    font-family: SomfySansRegular, sans-serif;
  }
}

@mixin flex-box() {
  display: flex;
}

@mixin flex-box-vertical-align-center {
  @include flex-box();
  align-items: center;
}

@mixin flex-box-space-between {
  @include flex-box();
  justify-content: space-between;
}

@mixin flex-box-column {
  @include flex-box();
  flex-direction: column;
}

@mixin center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin button($color, $clickFeedback: true) {
  $buttonHeight: 30px;
  position: relative;
  background-color: $color;
  border-radius: 15px;
  -webkit-appearance: button;
  padding: 0 8px;
  margin: 0;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  min-height: $buttonHeight;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.87rem;
  font-family: SomfySansRegular;
  @if $color == $blue or $color == $yellow {
    color: white;
    > a {
      color: white;
    }
  } @else if $color == white {
    color: $blue-grey;
    > a {
      color: $blue-grey;
    }
    border: solid 1px $very-light-pink;
  } @else {
    color: white;
    > a {
      color: white;
    }
  }
  &:disabled,
  &.disabled {
    &.with-pointer-events {
      pointer-events: auto !important;
    }

    opacity: 0.5;
    cursor: default;
  }

  > p {
    margin: 0 10px;
  }

  > a {
    text-decoration: none;
  }

  @if ($clickFeedback) {
    &:active {
      position: relative;
      top: 1px;
    }
  }
  &:focus {
    outline: none;
  }
  > img {
    $margin: 8px;

    &.icon-left {
      margin-right: $margin;
    }

    &.icon-right {
      margin-left: $margin;
    }
  }
}

@mixin square-button($color, $radius: 6.5px, $clickFeedBack: true) {
  @include button($color, $clickFeedBack);
  padding: 0.75rem 1.1rem 0.75rem;
  img {
    width: 1vw;
  }
  p {
    font-family: SomfySansRegular;
  }
  border-radius: $radius;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

@mixin modal-body {
  color: $blue-grey;
  form + span {
    font-size: 12px;
    font-family: SomfySansLight;
    text-align: center;
    color: $blue-grey;
    width: 100%;
    display: block;
    margin: 2vh 0;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;

    button:first-child {
      @include square-button($yellow);
    }

    button:nth-child(2) {
      @include square-button($blue-grey);
    }
  }
}

@mixin modal-footer {
  display: flex;
  justify-content: center;
  border: none;
  .buttons {
    display: flex;
    justify-content: space-around;

    button:first-child {
      @include square-button($yellow);
    }

    button:nth-child(2) {
      @include square-button($blue-grey);
    }
  }
}

// http://aslanbakan.com/en/blog/browser-and-device-specific-css-styles-with-sass-and-less-mixins/
@mixin safari() {
  html[data-browser-safari='true'] & {
    @content;
  }
}

@mixin safariIOS() {
  html[data-browser-iOSSafari='true'] & {
    @content;
  }
}

@mixin hideScrollBars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}

@mixin remote-pairing-step-main {
  @include modal-body();
  @include flex-box-column();
  overflow: hidden;
  height: 65vh;
  padding: 0 15px;
  h4 {
    &.title {
      text-align: center;
    }

    font-family: SomfySansMedium;
    font-size: 18px;
    color: $blue;
  }
  > .warning-and-protocols {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3vh;
    font-family: SomfySansLight;
    font-size: 14px;
  }
  > .warning-enduser {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3vh;
    font-family: SomfySansMedium;
    font-size: 1rem;
    color: $blue;
  }
  .devices-list {
    $itemHeight: 8vh;
    margin-top: 0.5vh;
    overflow: hidden;

    ul {
      overflow-y: auto;
      width: 100%;
      height: $itemHeight * 5;
      // padding-right: 4vw;
      li {
        margin-bottom: 0.9vw;
        margin-top: 0.9vw;
        height: $itemHeight;
        border-bottom: solid 1px $blue-grey;
        @include flex-box();
        align-items: center;

        label {
          @include flex-box();
          align-items: center;
          padding: 0 1vw;

          span {
            font-family: SomfySansMedium;
            color: $blue;
            font-size: 12px;
          }

          img {
            width: 2vw;
          }

          div {
            margin-left: 0.75vw;
          }
        }
      }
    }
  }
  p {
    margin-top: 2vh;
    color: $yellow;
    text-align: center;
  }
  p.grey {
    background-color: transparent !important;
    white-space: pre-line;
    word-break: break-word;
    color: $blue-grey;
    text-align: left;
    font-size: 16px;
    font-family: SomfySansRegular;
  }
}

@mixin remote-pairing-step-footer {
  height: 6.5vh;
  align-items: center;
  @include modal-footer();
  .buttons {
    width: 50%;

    button.ok,
    button.ok-btn,
    button.btn-ok {
      @include square-button($yellow);
    }

    button.cancel,
    button.cancel-btn,
    button.btn-cancel {
      @include square-button($blue-grey);
    }
  }
}
