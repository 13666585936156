mat-dialog-actions {
  display: flex;
  justify-content: space-evenly;
}

// Checkbox

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  opacity: 0;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  opacity: 0;
}

.mdc-checkbox,
.mdc-checkbox:not(:disabled):active {
  .mdc-checkbox__native-control {
    &:enabled:checked,
    &:enabled:indeterminate,
    &[data-indeterminate='true']:enabled {
      ~ .mdc-checkbox__background {
        border-color: $yellow !important;
        background-color: $yellow !important;
      }
    }
  }
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  background-color: $yellow !important;
}

// List

.mdc-list-item--selected .mdc-list-item__primary-text {
  color: inherit;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}

// Slider

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #fab800;
  --mdc-slider-focus-handle-color: #fab800;
  --mdc-slider-hover-handle-color: #fab800;
  --mdc-slider-active-track-color: #fab800;
  --mdc-slider-inactive-track-color: #fab800;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #fab800;
  --mat-mdc-slider-ripple-color: #fab800;
  --mat-mdc-slider-hover-ripple-color: rgba(250, 184, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(250, 184, 0, 0.2);
}

// Slide Toggle

.mat-mdc-slide-toggle {
  .mdc-switch:enabled .mdc-switch__track::after {
    background: $yellow !important;
  }

  .mdc-switch .mdc-switch__track {
    height: 25px !important;
    border-radius: 12.5px !important;
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: white !important;
  }

  .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::before,
  .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after,
  .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before,
  .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after {
    background-color: $yellow !important;
  }

  .mdc-switch {
    width: 48px !important;

    .mdc-switch__focus-ring-wrapper {
      height: 22px !important;
    }

    .mdc-switch__handle {
      height: 22px !important;
      width: 22px !important;
      border-radius: 11px !important;
    }

    .mdc-switch__handle-track {
      width: calc(100% - 24px) !important;
      margin-left: 1px;
    }
  }
}

mat-slide-toggle .mdc-form-field > label {
  padding-left: 0;
}

// Text Input

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 1.9rem;
}

.mat-mdc-form-field-infix {
  min-height: 54px;
}

.mat-mdc-form-field-infix {
  min-height: 35px !important;
}

// Buttons

.mat-mdc-button:disabled {
  // Override Material lib
  color: white;
}
